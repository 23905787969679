import moment from "moment";
import { getUserId } from "services/utils";
import { formatHSA } from "./formatHSA";
import { getDateFromMonth } from "utils/date";

export const formatPlans = async (values, year) => {
  const {
    table,
    startDate,
    frequency,
    group,
    planOptions,
    withMultiplePremiumTiers,
    withSpouseSurcharge,
    spousalSurcharge,
    spousalSurchargeFrequency,
    waivers,
    withWaiverPayment,
    FSType,
    FSMaxUnits,
    FSMaxAmount,
  } = values;
  let { serviceList } = values;
  serviceList = serviceList.map((item) => item.value);
  const plans = table.slice(0, planOptions);

  let groupName = "";
  try {
    const userId = await getUserId();
    groupName = group + " " + userId;
  } catch (error) {
    groupName = group + " " + Math.random().toString(36).slice(-6).toUpperCase();
  }

  const firstTiers = plans[0].tiers;

  const formattedPlans = plans.map((item) => {
    let { insuranceNetwork } = item;

    const {
      id,
      label,
      planName,
      outOfNetworkCoverage,
      inNetworkIndividual,
      inNetworkFamily,
      outOfNetworkIndividual,
      outOfNetworkFamily,
      outOfNetworkAndInNetworkCrossAccumulate,
      familyType,
      inNetworkIndividualInFamily,
      outOfNetworkIndividualInFamily,
      inNetworkIndividualOOP,
      inNetworkIndividualInFamilyOOP,
      inNetworkFamilyOOP,
      outOfNetworkIndividualOOP,
      outOfNetworkIndividualInFamilyOOP,
      outOfNetworkFamilyOOP,
      familyOOPType,
      services,
      tiers,
    } = item;

    const {
      inNetworkCoinsuranceRate,
      outOfNetworkCoinsuranceRate,
      haveToPay,
      amount,
      unit,
      maxUnits,
      inNetworkSubjectToDeductible,
    } = services?.["Inpatient Hospital Stay"] || {};

    insuranceNetwork =
      insuranceNetwork === "Blue Cross and/or Blue Shield (including Anthem)"
        ? "Blue Cross/Blue Shield"
        : insuranceNetwork;

    const formattedHSA = formatHSA(item);

    const date = getDateFromMonth(startDate, year);

    delete tiers["0"];

    Object.keys(tiers).forEach((tierKey) => {
      tiers[tierKey].range = firstTiers[tierKey].range;
    });

    const formatWaivers = (waivers) => {
      Object.keys(waivers).forEach((waiverKey) => {
        if (waivers[waiverKey] && !waivers[waiverKey].frequency) {
          waivers[waiverKey].frequency = 1 / 12;
        }

        if (!waivers[waiverKey]) {
          delete waivers[waiverKey];
        }
      });

      return waivers;
    };

    return {
      id: id,
      externalId: "CP" + Math.random().toString(36).slice(-6).toUpperCase(),
      name: planName && planName !== "" ? planName : label,
      network: label,
      broaderNetwork: insuranceNetwork.split(";")[0],
      insuranceCompany: insuranceNetwork.split(";")[1],
      group: groupName,
      service: "Inpatient Hospital Stay",
      planType: "Medical",
      linkSBC: "",
      premium: {
        ref: "",
        isUnique: true,
        frequency: frequency,
        surcharges: {
          smoking: 0,
          spouse: 0,
        },
        tiers: withMultiplePremiumTiers ? tiers : { 1: tiers["1"] },
        spousalSurcharge: withSpouseSurcharge ? spousalSurcharge : 0,
        spousalSurchargeFrequency: withSpouseSurcharge ? spousalSurchargeFrequency : 0,
        waivers: withWaiverPayment ? formatWaivers(waivers) : null,
      },
      inNetwork: {
        individual: {
          deductible: inNetworkIndividual,
          oopMax: inNetworkIndividualOOP != null ? inNetworkIndividualOOP : null,
        },
        family: {
          individualDeductible: inNetworkIndividualInFamily != null ? inNetworkIndividualInFamily : null,
          deductible: inNetworkFamily,
          individualOopMax: inNetworkIndividualInFamilyOOP != null ? inNetworkIndividualInFamilyOOP : null,
          oopMax: inNetworkFamilyOOP != null ? inNetworkFamilyOOP : null,
        },
        largeFamily: {
          deductible: null,
          oopMax: null,
        },
        coinsurance: {
          rate:
            haveToPay === "coinsurance" || haveToPay === "both"
              ? inNetworkCoinsuranceRate > 50
                ? (100 - inNetworkCoinsuranceRate) / 100
                : inNetworkCoinsuranceRate / 100
              : 0,
        },
      },
      outOfNetwork: {
        coverage: outOfNetworkCoverage === "yes",
        individual: {
          deductible:
            outOfNetworkCoverage === "yes" ? (outOfNetworkIndividual != null ? outOfNetworkIndividual : null) : null,
          oopMax:
            outOfNetworkCoverage === "yes"
              ? outOfNetworkIndividualOOP != null
                ? outOfNetworkIndividualOOP
                : null
              : null,
        },
        family: {
          individualDeductible:
            outOfNetworkCoverage === "yes"
              ? outOfNetworkIndividualInFamily != null
                ? outOfNetworkIndividualInFamily
                : null
              : null,
          deductible: outOfNetworkCoverage === "yes" ? (outOfNetworkFamily != null ? outOfNetworkFamily : null) : null,
          individualOopMax:
            outOfNetworkCoverage === "yes"
              ? outOfNetworkIndividualInFamilyOOP != null
                ? outOfNetworkIndividualInFamilyOOP
                : null
              : null,
          oopMax:
            outOfNetworkCoverage === "yes" ? (outOfNetworkFamilyOOP != null ? outOfNetworkFamilyOOP : null) : null,
        },
        largeFamily: {
          deductible: null,
          oopMax: null,
        },
        coinsurance: {
          rate:
            outOfNetworkCoverage === "yes"
              ? outOfNetworkCoinsuranceRate > 50
                ? (100 - outOfNetworkCoinsuranceRate) / 100
                : outOfNetworkCoinsuranceRate / 100
              : null,
        },
      },
      deductibles: {
        cross: {
          isAccumulatable: outOfNetworkCoverage === "yes" ? outOfNetworkAndInNetworkCrossAccumulate === "yes" : null,
        },
      },
      family: {
        deductible: {
          type: familyType || "Aggregated",
        },
        oopMax: {
          type: familyOOPType || "Aggregated",
        },
      },
      baby: {
        deductible: {
          isUsed: true,
        },
      },
      startDate: date.format("MM/DD/YYYY"),
      services: serviceList.map((serviceName) => {
        const { haveToPay, inNetworkCoinsuranceRate, amount, unit, maxUnits, inNetworkSubjectToDeductible } =
          services?.[serviceName] || {};
        return {
          serviceName: serviceName,
          maternityCopayDetails: {
            subjToCopay: haveToPay === "copay" || haveToPay === "both",
            hospital: haveToPay === "copay" || haveToPay === "both" ? amount : null,
            unit: haveToPay === "copay" || haveToPay === "both" ? unit : "n/a",
            maxUnits: (haveToPay === "copay" || haveToPay === "both") && unit === "night" ? maxUnits : null,
            maxUnitsCovered:
              serviceName === "Fertility Services (IVF, Egg Freezing)"
                ? FSType === "amount"
                  ? FSMaxAmount
                  : FSMaxUnits
                : null,
            maxUnitsCoveredUnit: serviceName === "Fertility Services (IVF, Egg Freezing)" ? FSType : null,
            whoPays: haveToPay === "copay" || haveToPay === "both" ? "Parent" : "n/a",
            subjToDeductible: inNetworkSubjectToDeductible !== "no",
            subjToPercentCoins: haveToPay === "coinsurance" || haveToPay === "both",
          },
          inNetwork: {
            coinsurance: {
              rate:
                haveToPay === "coinsurance" || haveToPay === "both"
                  ? inNetworkCoinsuranceRate > 50
                    ? (100 - inNetworkCoinsuranceRate) / 100
                    : inNetworkCoinsuranceRate / 100
                  : 0,
            },
          },
        };
      }),
      maternityCopayDetails: {
        subjToCopay: haveToPay === "copay" || haveToPay === "both",
        hospital: haveToPay === "copay" || haveToPay === "both" ? amount : null,
        unit: haveToPay === "copay" || haveToPay === "both" ? unit : "n/a",
        maxUnits: (haveToPay === "copay" || haveToPay === "both") && unit === "night" ? maxUnits : null,
        maxUnitsCovered: null,
        whoPays: haveToPay === "copay" || haveToPay === "both" ? "Parent" : "n/a",
        subjToDeductible: inNetworkIndividual === 0 ? true : inNetworkSubjectToDeductible !== "no",
        subjToPercentCoins: haveToPay === "coinsurance" || haveToPay === "both",
      },
      hsa: formattedHSA,
    };
  });
  return formattedPlans;
};
