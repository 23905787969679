const bcbsDisplayNameMap = {
  "Anthem Blue Cross and/or Blue Shield": "Anthem",
  "Arkansas Blue Cross and Blue Shield": "Arkansas Blue Cross",
  "Blue Cross & Blue Shield of Mississippi": "BCBS of MS",
  "Blue Cross & Blue Shield of Rhode Island": "BCBS of RI",
  "Blue Cross and Blue Shield of Alabama": "BCBS of AL",
  "Blue Cross and Blue Shield of Hawaii": "BCBS HMSA",
  "Blue Cross and Blue Shield of Illinois": "BCBS of IL",
  "Blue Cross and Blue Shield of Kansas": "BCBS of KS",
  "Blue Cross and Blue Shield of Kansas City": "Blue Kansas City",
  "Blue Cross and Blue Shield of Louisiana": "BCBS of LA",
  "Blue Cross and Blue Shield of Massachusetts": "BCBS of MA",
  "Blue Cross and Blue Shield of Minnesota": "Blue Cross MN",
  "Blue Cross and Blue Shield of Montana": "BCBS of MT",
  "Blue Cross and Blue Shield of Nebraska": "Nebraska Blue",
  "Blue Cross and Blue Shield of New Mexico": "BCBS of NM",
  "Blue Cross and Blue Shield of North Carolina": "Blue Cross NC",
  "Blue Cross and Blue Shield of Oklahoma": "BCBS of OK",
  "Blue Cross and Blue Shield of South Carolina": "South Carolina Blues",
  "Blue Cross and Blue Shield of Texas": "BCBS of TX",
  "Blue Cross and Blue Shield of Vermont": "BCBS of VT",
  "Blue Cross Blue Shield of Arizona": "Arizona Blue",
  "Blue Cross Blue Shield of Michigan": "BCBS of Michigan",
  "Blue Cross Blue Shield of North Dakota": "BCBSND",
  "Blue Cross Blue Shield of Wyoming": "BCBS of WY",
  "Blue Cross of Idaho": "Blue Cross of Idaho",
  "Blue Shield of California": "Blue Shield California",
  "BlueCross BlueShield of Puerto Rico": "BCBS of Puerto Rico",
  "BlueCross BlueShield of Tennessee": "BCBS of TN",
  "Capital Blue Cross": "Capital Blue Cross",
  "CareFirst BlueCross BlueShield": "CareFirst",
  "Excellus BlueCross BlueShield": "Excellus",
  "Florida Blue": "Florida Blue",
  "Highmark Blue Cross and/or Blue Shield": "Highmark",
  "Horizon Blue Cross and Blue Shield of New Jersey": "Horizon",
  "Independence Blue Cross": "IBX",
  "Premera Blue Cross and/or Blue Shield": "Premera",
  "Regence Blue Cross and/or Blue Shield": "Regence",
  "Wellmark Blue Cross and Blue Shield": "Wellmark",
};

const uhcDisplayNameMap = {
  UnitedHealthcare: "United Healthcare",
};

export const insuranceNetworkDisplayNameMap = {
  ...bcbsDisplayNameMap,
  ...uhcDisplayNameMap,
};
