import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "components";
import { SPACE_BEFORE_TABLES, SPACE_BETWEEN_TABLES, StyledCell } from "components/organisms/AllMyOptions";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import Actions from "store/report/actions";
import AllPlansModal from "components/organisms/AllPlansModal";
import { formatCurrency } from "utils/currency";
import { insuranceNetworkDisplayNameMap } from "enums/insuranceNetworkDisplayNameMap.enums";

// import "./index.css";

const RowLineWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  transition: all 0.3s ease;
  opacity: isFilteredRow && 0.3;
  ${({ isStriked }) => isStriked && `text-decoration: line-through;`}
  ${({ isHidden }) => isHidden && `display: none;`}
  width: max-content;
  ${({ isStart }) => isStart && `justify: start;`}
  align-items: stretch;

  > .ant-radio-group {
    display: flex !important;
    flex-direction: row !important;

    > div {
      height: 100% !important;
    }
  }

  &.is-starred-row > div:first-child > div {
    background: #21335b;
    border: 1px solid #fff;

    p {
      color: #fff !important;
    }
  }
`;

const TableBodyWrapper = styled.div`
  width: max-content;
  > div {
    width: max-content;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  ${({ highlght }) => highlght && `background: #21335b; border: 1px solid #fff;`}
`;

const selectValue = (item, planMode, costPeriod, children, isSpouseTable, hasKid) => {
  if (planMode === "samePlan") {
    return item.costs?.samePlan[costPeriod]["grandTotal"];
  } else {
    if (children === "birthParent") {
      if (!isSpouseTable && hasKid) {
        return item.costs?.separatePlans?.withChildren[costPeriod]["grandTotal"];
      } else {
        return item.costs?.separatePlans?.individual[costPeriod]["grandTotal"];
      }
    } else {
      if (isSpouseTable && hasKid) {
        return item.costs?.separatePlans?.withChildren[costPeriod]["grandTotal"];
      } else {
        return item.costs?.separatePlans?.individual[costPeriod]["grandTotal"];
      }
    }
  }
};

const selectDeductibleTypeValue = (item, plansMode, deductibleType, children, isSpouseTable) => {
  if (deductibleType === "individual") {
    return item["individual"];
  } else {
    if (plansMode === "samePlan") {
      return item["family"];
    } else {
      if (children === "birthParent") {
        if (!isSpouseTable) {
          return item["family"];
        } else {
          return item["individual"];
        }
      } else {
        if (isSpouseTable) {
          return item["family"];
        } else {
          return item["individual"];
        }
      }
    }
  }
};

const calculateValue = (array) => {
  let results = {
    displayValue: null,
    avgValue: null,
  };

  if (array) {
    const min = Math.round(Math.min(...array));
    const max = Math.round(Math.max(...array));

    const value =
      min === max
        ? formatCurrency(min)
        : `${formatCurrency(min)} - ${formatCurrency(max)}`.split(" ").map((value) => <span key={value}>{value}</span>);

    results.displayValue = (
      <span
        style={{
          paddingLeft: 6,
          paddingRight: 6,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: 4,
          textAlign: "center",
        }}
      >
        {value}
      </span>
    );
    results.avgValue = Math.round((min + max) / 2);
  }

  return results;
};

const getBackground = (avgValue, deductibleType, isRecommendedRow, isGreen, isRecommendedCol) => {
  return !(avgValue < 1000000) || deductibleType ? "" : isGreen ? "green" : "red";
};

/**
 * Checks if a column is filtered based on the plan and preference.
 *
 * @param {Object} plan - The plan object. It should have either a `planName` or `name` property.
 * @param {string} [plan.planName] - The plan name.
 * @param {string} [plan.name] - The plan name.
 * @param {Object} preference - The preference object. It should have `selectedCategories` and `join` properties.
 * @param {string[]} preference.selectedCategories - The selected categories.
 * @param {string} preference.join - The join selected of the global filter
 * @param {"birthParent"|"partner"} joinType - The join type of the current column. It should be either `birthParent` or `partner`.
 *
 * @returns {boolean} - Returns `true` if the column is filtered, `false` otherwise.
 */
export const checkColumnIsFiltered = (plan, preference, joinType = null) => {
  const selectedCategories = preference?.selectedCategories;
  if (!selectedCategories?.length) return false;
  const category = plan?.planName || plan?.name;

  // If plans to join is either birthParent or partner company, we filter out the columns of the other company
  const plansToJoin = preference?.join;
  if (["birthParent", "partner"].includes(plansToJoin) && joinType !== plansToJoin) {
    return true;
  }

  return category && !selectedCategories?.includes(category);
};

export const checkPlansIsFilteredOut = (plan, selectedPlans) => {
  if (!selectedPlans?.length) return false;
  if (!plan) return false;
  const planId = plan?.externalId;
  return planId && !selectedPlans?.map((item) => item?.externalId)?.includes(planId);
};

const getCellInformation = (
  plan,
  plans,
  plansMode,
  costPeriod,
  children,
  isSpouseTable,
  currentStats,
  deductibleType,
  hasKid,
  preference,
  allPlanOptions,
) => {
  let valueArray = [];
  let allPlans = [];

  const selectedPlans = preference?.selectedPlans?.map((plan) => allPlanOptions?.find((p) => p.id === plan));

  if (!deductibleType) {
    plans.length > 0 &&
      plans
        .filter((plan) => !checkPlansIsFilteredOut(plan, selectedPlans))
        .map((item) => {
          const value = selectValue(item, plansMode, costPeriod, children, isSpouseTable, hasKid);
          valueArray = [...valueArray, value];
        });
    allPlans =
      plans.length > 0 &&
      plans
        .filter((plan) => !checkPlansIsFilteredOut(plan, selectedPlans))
        .map((item) => {
          return {
            key: item.externalId,
            name: item.longName,
            value: selectValue(item, plansMode, costPeriod, children, isSpouseTable, hasKid),
            // ?.toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
          };
        })
        .sort((a, b) => b.value - a.value);
  } else {
    const value = selectDeductibleTypeValue(plan, plansMode, deductibleType, children, isSpouseTable);
    valueArray = value;
  }

  let unique = [...new Set(valueArray)];
  const { displayValue, avgValue } = calculateValue(unique);
  const isGreen =
    // For monthly premiums, lower is always better
    costPeriod === "monthly"
      ? avgValue <= currentStats?.avg
      : // For yearly spend, it depends on if HSA benefits is turned on
      preference?.hsaHraImpact === "spending-hsa-hra"
      ? avgValue >= currentStats?.avg
      : avgValue <= currentStats?.avg;

  let opacity = 1;
  if (avgValue <= currentStats?.avg) {
    opacity = mapRange(avgValue, currentStats?.min, currentStats?.avg, 1, 0.1);
  } else {
    opacity = mapRange(avgValue, currentStats?.avg, currentStats?.max, 0.1, 1);
  }
  const isColumnFiltered = checkColumnIsFiltered(plan, preference, isSpouseTable ? "partner" : "birthParent");

  return { allPlans, valueArray, displayValue, avgValue, isGreen, opacity, isColumnFiltered };
};

const mapRange = (value, x1, y1, x2, y2) => {
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
};

/**
 * @param {Object} props
 * @param {import("services/utils/getRecommendations").OptimizationPlan[]} props.bestOptimization
 * @param {{externalId: string}[]} props.plansForThisCell
 * @param {"samePlan"|"separatePlans"} props.currentPlansMode
 * @param {"birthParent"|"partner"} [props.currentChildrenMode]
 * @param {boolean} props.isSpouseTable
 * @returns {boolean}
 */
const isCellRecommended = ({
  bestOptimization,
  plansForThisCell,
  currentPlansMode,
  currentChildrenMode,
  isSpouseTable,
}) => {
  if (!bestOptimization?.length || !plansForThisCell.length) return false;
  const bestOptimizationForThisCell = bestOptimization.filter(
    (optimization) =>
      optimization?.planType === currentPlansMode && optimization?.childrenPlanType === currentChildrenMode,
  );
  if (!bestOptimizationForThisCell.length) return false;
  return bestOptimizationForThisCell.some((optimization) => {
    const { birthParentPlan, partnerPlan } = optimization;
    if (isSpouseTable) {
      return plansForThisCell?.map((p) => p.externalId)?.includes(partnerPlan.externalId);
    } else {
      return plansForThisCell?.map((p) => p.externalId)?.includes(birthParentPlan.externalId);
    }
  });
};

const renderRows = (
  plansNames,
  plansMode,
  deductibleType,
  groupType,
  broaderNetwork,
  recommendedPlan,
  sortedPlans,
  costPeriod,
  /**
   * @type {import("doctypes/plans").PlansStatistics}
   */
  plansStats,
  /**
   * @type {import("doctypes/plans").PlansStatistics}
   */
  allPlansStats,
  comparisonMode,
  isRecommendedRow,
  _i,
  company,
  handleCompare,
  size,
  children,
  isSpouseTable,
  hasKid,
  isInNetworkLimits,
  dispatch,
  reportVersion,
  preference,
  recommendedDisplayValue,
  bestOptimization,
  allPlanOptions,
  tableType,
) => {
  return plansNames.map((plan, index) => {
    let planNamesInNetwork = null;
    if (isInNetworkLimits) {
      planNamesInNetwork = plan[1];
      plan = plan[0];
    }
    let { planName, plans } = plan;
    if (isInNetworkLimits) {
      planName = plan.name;
    }
    const isRecommendedCol = recommendedPlan === planName;
    const currentStats = plansMode === "samePlan" ? allPlansStats : plansStats;

    let { allPlans, valueArray, displayValue, avgValue, isGreen, opacity, isColumnFiltered } = getCellInformation(
      plan,
      plans,
      plansMode,
      costPeriod,
      children,
      isSpouseTable,
      currentStats,
      deductibleType,
      hasKid,
      preference,
      allPlanOptions,
    );

    if (isColumnFiltered) return null;

    // If value is equal to recommended then we can consider this row to also be recommended
    if (displayValue && recommendedDisplayValue && displayValue === recommendedDisplayValue) {
      isRecommendedRow = true;
    }

    const isStarred = isCellRecommended({
      bestOptimization,
      plansForThisCell: plan?.plans,
      currentPlansMode: plansMode,
      currentChildrenMode: children,
      isSpouseTable,
    });

    let newAllPlans = [];
    if (isInNetworkLimits) {
      for (let [i, planNameInNetwork] of planNamesInNetwork.entries()) {
        newAllPlans.push({
          name: planNameInNetwork,
          planName: planNameInNetwork,
          value: valueArray[i],
          key: i,
        });
      }

      allPlans = newAllPlans;
    }
    const isComparisonMode =
      comparisonMode === "on" &&
      avgValue < 1000000 &&
      !(isRecommendedCol && isRecommendedRow && valueArray.length === 1) &&
      !deductibleType;

    const isClickable = ((!isInNetworkLimits && allPlans.length > 1) || reportVersion === "v2") && allPlans.length > 0;

    const background = getBackground(avgValue, deductibleType, isRecommendedRow, isGreen, isRecommendedCol);

    return (
      <StyledCell
        span={3}
        align="center"
        key={index}
        bordered
        relative={isStarred}
        className={isStarred ? "is-starred" : ""}
        isComparisonMode={isComparisonMode || (valueArray.length > 1 && !isInNetworkLimits)}
        isClickable={isClickable}
        isInNetworkLimits={isInNetworkLimits}
        onClick={() => {
          if (isClickable) {
            dispatch(
              Actions.Creators.switchState({
                planSelectedFrom: isSpouseTable ? "partner" : "birthParent",
              }),
            );
            handleCompare(
              broaderNetwork,
              planName,
              allPlans,
              valueArray,
              company,
              isSpouseTable,
              isComparisonMode,
              tableType,
            );
          }
        }}
        background={background}
        opacity={opacity}
      >
        {isComparisonMode && (
          <Radio
            style={{ marginRight: "auto" }}
            onClick={() => {}}
            value={JSON.stringify({
              externalId: plans[0]?.externalId,
              network: broaderNetwork,
              planName: planName,
              isArray: valueArray.length > 1,
            })}
          ></Radio>
        )}
        <Text
          lightGrey2={isInNetworkLimits}
          blue={!isInNetworkLimits}
          fontSize="18"
          bold={!!background}
          comparisonMode={isComparisonMode}
        >
          {avgValue < 1000000 ? displayValue : "-"}
        </Text>
        {isStarred ? (
          <div style={{ position: "absolute", top: 5, right: 5 }} title="Recommended">
            <svg
              style={{ width: "20px", height: "20px", fill: "#FDB022" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : (
          <></>
        )}
      </StyledCell>
    );
  });
};

const getPlanFromExternalId = (planOptions, externalId) => {
  return planOptions.find((plan) => plan.externalId === externalId);
};

/**
 *
 * @param {Object} props - The props object.
 * @param {Array<Object>} props.broaderNetworks - The broader networks.
 * @param {Array<string>} props.columnHeadersBP - The column headers for the birth parent.
 * @param {Array<string>} props.columnHeadersPartner - The column headers for the partner.
 * @param {Array<Object>} props.sortedBirthParentPlans - The sorted birth parent plans.
 * @param {Array<Object>} props.sortedPartnerPlans - The sorted partner plans.
 * @param {string} props.premiumTierBP - The premium tier for the birth parent.
 * @param {string} props.premiumTierPartner - The premium tier for the partner.
 * @param {number} props.avgPremium - The average premium.
 * @param {string} props.groupType - The group type.
 * @param {string} props.deductibleType - The deductible type.
 * @param {Function} props.getCollapseProps - The function to get the collapse props.
 * @param {string} props.birthParentCompany - The birth parent company.
 * @param {string} props.partnerCompany - The partner company.
 * @param {number} props.indexToSplit - The index to split.
 * @param {import("doctypes/plans").PlansStatistics} props.birthParentPlansStats - The birth parent plans statistics.
 * @param {import("doctypes/plans").PlansStatistics} props.partnerPlansStats - The partner plans statistics.
 * @param {import("doctypes/plans").PlansStatistics} props.allPlansStats - The combined plans statistics.
 * @param {Array<Object>} props.inNetworkLimits - The in-network limits.
 * @param {string} props.reportVersion - The report version.
 * @param {boolean} props.isJobOffer - Whether the report is a job offer.
 * @param {{hsaHraImpact: "spending-hsa-hra" | "spending-only"}} props.preference - The preference.
 */
const TableBody = ({
  broaderNetworks,
  columnHeadersBP,
  columnHeadersPartner,
  sortedBirthParentPlans,
  sortedPartnerPlans,
  premiumTierBP,
  premiumTierPartner,
  avgPremium,
  //groupType,
  deductibleType,
  getCollapseProps,
  birthParentCompany,
  partnerCompany,
  indexToSplit,
  birthParentPlansStats,
  partnerPlansStats,
  allPlansStats,
  inNetworkLimits,
  reportVersion,
  isJobOffer,
  preference,
  bestOptimization,
  allPlanOptions,
  tableType,
}) => {
  const broaderNetworkIncludes = useSelector((state) => state.report.broaderNetworkIncludesOnLoad);

  const [recommendedDisplayValue, setRecommendedDisplayValue] = useState(null);
  const recommendedPlan = useSelector((state) => state.report.recommendedPlan);
  const recommendedId = useSelector((state) => state.report.recommendedId);

  const recommendedNetwork = useSelector((state) => state.report.recommendedNetwork);

  const comparisonMode = useSelector((state) => state.report.comparisonMode);

  const plansMode = useSelector((state) => state.report.plansMode);
  const join = useSelector((state) => state.report.join);
  const detailsJoin = useSelector((state) => state.report.detailsJoin);

  const children = useSelector((state) => state.report.children);
  const childrenRecommended = useSelector((state) => state.report.childrenRecommended);
  const hasKid = useSelector((state) => state.report.hasKid);

  const costPeriod = useSelector((state) => state.report.costPeriod);

  const comparedPlanYou = useSelector((state) => state.report.comparedPlanYou);
  const comparedPlanSpouse = useSelector((state) => state.report.comparedPlanSpouse);

  const doctorFilter = useSelector((state) => state.report.doctor);
  const hospitalFilter = useSelector((state) => state.report.hospital);
  const drugFilter = useSelector((state) => state.report.drug);

  const appliedDoctorFilter = useSelector((state) => state.report.appliedDoctor);
  const appliedHospitalFilter = useSelector((state) => state.report.appliedHospital);
  const appliedDrugFilter = useSelector((state) => state.report.appliedDrug);

  const [modal, setModal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const [rows, setRows] = useState([]);

  const [groupType, setGroupType] = useState("");

  const sameRecommendation = join === plansMode && children === childrenRecommended;

  useEffect(() => {
    let groupType = "";
    if (plansMode === "same") {
      groupType = "mo";
    }
    setGroupType(groupType);
  }, [plansMode, children, costPeriod]);

  useEffect(() => {
    const objComparedPlanYou = comparedPlanYou && JSON.parse(comparedPlanYou);
    const objComparedPlanSpouse = comparedPlanSpouse && JSON.parse(comparedPlanSpouse);
    recommendedId.includes(objComparedPlanYou.externalId) &&
      dispatch(
        Actions.Creators.switchState({
          comparedPlanYou: "",
        }),
      );
    recommendedId.includes(objComparedPlanSpouse.externalId) &&
      dispatch(
        Actions.Creators.switchState({
          comparedPlanSpouse: "",
        }),
      );
  }, [children]);

  const dispatch = useDispatch();

  const handleCompare = (network, planName, plans, plansValues, company, isSpouseTable, comparisonMode, tableType) => {
    setModalVisible(true);
    setModal({
      network,
      planName,
      plans,
      plansValues,
      company,
      isSpouseTable,
      comparisonMode,
      tableType,
    });
  };

  const isInNetworkLimits = inNetworkLimits?.length > 0;
  useEffect(() => {
    if (isInNetworkLimits) {
      setRows(inNetworkLimits);
    } else {
      let isFilteredRow = false;
      let isRowHidden = false;

      const rowsWithFilters = broaderNetworks?.map((network, _i) => {
        const { providers, broaderNetwork } = network;
        const { doctor, drug, hospital } = providers;
        let hospitalFiltered = !hospitalFilter.every((i) => hospital.includes(i));
        let drugFiltered = !drugFilter.every((i) => drug.includes(i));
        let doctorFiltered = !doctorFilter.every((i) => doctor.includes(i));
        isFilteredRow = hospitalFiltered || drugFiltered || doctorFiltered;

        const networkHidden = broaderNetworkIncludes?.length > 0 && !broaderNetworkIncludes.includes(broaderNetwork);
        isRowHidden = networkHidden;

        return { ...network, isFilteredRow, isRowHidden };
      });
      setRows(rowsWithFilters);
    }
  }, [
    broaderNetworks,
    appliedHospitalFilter,
    appliedDrugFilter,
    appliedDoctorFilter,
    inNetworkLimits,
    broaderNetworkIncludes,
  ]);

  const size = rows?.length;

  function setIsStarredRow() {
    // Setting Whole row as starred if a single child is recommended
    setTimeout(() => {
      const currentStarredRows = document?.querySelectorAll(".is-starred-row");
      if (currentStarredRows?.length) {
        currentStarredRows.forEach((thisRow) => {
          thisRow.classList.remove("is-starred-row");
        });
      }

      const isStarredCells = document?.querySelectorAll(".is-starred");
      if (isStarredCells?.length) {
        isStarredCells.forEach((thisCell) => {
          const parentRow = thisCell?.parentElement?.parentElement;
          if (!parentRow) return;
          parentRow.classList.add("is-starred-row");
        });
      }
    }, 500);
  }

  useEffect(() => {
    if (!rows || !recommendedNetwork?.length) return;

    const recommendedRow = recommendedNetwork[0];
    const recommendedRowPartner = plansMode === "samePlan" ? recommendedNetwork[0] : recommendedNetwork[1];
    const recommendedColumn = recommendedPlan[0];
    const recommendedColumnPartner = plansMode === "samePlan" ? recommendedPlan[0] : recommendedPlan[1];

    const bestRowBirth = rows.find((thisRow) => thisRow.broaderNetwork === recommendedRow);
    const bestCellBirth = bestRowBirth?.plansNames?.find((thisPlan) => thisPlan.planName === recommendedColumn);

    const bestRowPartner = rows.find((thisRow) => thisRow.broaderNetwork === recommendedRowPartner);
    const bestCellPartner = bestRowPartner?.plansNames?.find(
      (thisPlan) => thisPlan.planName === recommendedColumnPartner,
    );

    function getDisplayValue(bestCell) {
      if (!bestCell) return;

      let { displayValue } = getCellInformation(
        bestCell,
        bestCell.plans,
        plansMode,
        costPeriod,
        children,
        false,
        null,
        deductibleType,
        hasKid,
        preference,
      );
      return displayValue;
    }

    const birthValue = getDisplayValue(bestCellBirth);
    const partnerValue = getDisplayValue(bestCellPartner);

    if (!birthValue || !partnerValue) return;

    const birthRaw = parseInt(String(birthValue).replace(/\D/g, "") || "0");
    const partnerRaw = parseInt(String(partnerValue).replace(/\D/g, "") || "0");

    const newRecommendedDisplayValue = birthRaw < partnerRaw ? birthValue : partnerValue;

    setRecommendedDisplayValue(newRecommendedDisplayValue);
    setIsStarredRow();
  }, [
    rows,
    recommendedPlan,
    recommendedNetwork,
    plansMode,
    costPeriod,
    children,
    avgPremium,
    deductibleType,
    hasKid,
    preference,
  ]);

  const broaderNetworkCount = {};

  if (rows) {
    rows.forEach((row) => {
      const { insuranceCompany } = row;
      if (!broaderNetworkCount[insuranceCompany]) {
        broaderNetworkCount[insuranceCompany] = 0;
      }
      broaderNetworkCount[insuranceCompany]++;
    });
  }

  return (
    <TableBodyWrapper>
      <div {...getCollapseProps()}>
        {rows?.map((network, _i) => {
          let { broaderNetwork, insuranceCompany, plansNames, isFilteredRow, isRowHidden } = network;

          const isRecommendedRow =
            (plansMode === "samePlan"
              ? recommendedNetwork[0] === broaderNetwork && detailsJoin === "birthParent"
              : recommendedNetwork[0] === broaderNetwork) && sameRecommendation;
          const isRecommendedRowPartner =
            (plansMode === "samePlan"
              ? recommendedNetwork[0] === broaderNetwork && detailsJoin === "partner"
              : recommendedNetwork[1] === broaderNetwork) &&
            sameRecommendation &&
            !isJobOffer;
          const recommendedColumn = recommendedPlan[0];
          const recommendedColumnPartner = plansMode === "samePlan" ? recommendedPlan[0] : recommendedPlan[1];
          const birthParentPlansNames = plansNames.slice(0, indexToSplit);
          const partnerPlansNames = plansNames.slice(indexToSplit);

          let firstColumn = "";
          if (insuranceCompany) {
            const insuranceCompanyDisplayName = insuranceNetworkDisplayNameMap[insuranceCompany] || insuranceCompany;
            firstColumn =
              broaderNetworkCount[insuranceCompany] === 1
                ? insuranceCompanyDisplayName
                : `${insuranceCompanyDisplayName} ${broaderNetwork}`;
          } else {
            firstColumn = broaderNetwork;
          }

          return (
            <RowLineWrapper
              key={network?.broaderNetwork}
              isStriked={isFilteredRow}
              isHidden={isRowHidden}
              isStart={columnHeadersBP.length + columnHeadersPartner.length > 7}
            >
              <StyledCell customWidth={`${SPACE_BEFORE_TABLES}px`} hFit>
                <Wrapper highlght={isRecommendedRow || isRecommendedRowPartner}>
                  <Text
                    lightGrey2={isInNetworkLimits}
                    fontSize="18"
                    bold={!isInNetworkLimits}
                    blue={!isInNetworkLimits}
                    justStart
                    left
                    white={isRecommendedRow || isRecommendedRowPartner}
                    customLSpacing="0"
                  >
                    {firstColumn}
                  </Text>
                </Wrapper>
              </StyledCell>
              <Radio.Group
                onChange={(e) => {
                  dispatch(
                    Actions.Creators.switchState({
                      planSelectedFrom: "birthParent",
                    }),
                  );
                  const isArray = JSON.parse(e.target.value).isArray;
                  !isArray &&
                    dispatch(
                      Actions.Creators.switchState({
                        comparedPlanYou: e.target.value,
                      }),
                    );
                }}
                value={comparedPlanYou}
                style={{ display: "contents" }}
              >
                {renderRows(
                  birthParentPlansNames,
                  plansMode,
                  deductibleType,
                  groupType,
                  broaderNetwork,
                  recommendedColumn,
                  sortedBirthParentPlans,
                  costPeriod,
                  birthParentPlansStats,
                  allPlansStats,
                  comparisonMode,
                  isRecommendedRow,
                  _i,
                  birthParentCompany,
                  handleCompare,
                  size,
                  children,
                  false,
                  hasKid,
                  isInNetworkLimits,
                  dispatch,
                  reportVersion,
                  preference,
                  recommendedDisplayValue,
                  bestOptimization,
                  allPlanOptions,
                  tableType,
                )}
              </Radio.Group>
              <StyledCell hide={!columnHeadersPartner.length} customWidth={`${SPACE_BETWEEN_TABLES}px`} />
              <Radio.Group
                onChange={(e) => {
                  dispatch(
                    Actions.Creators.switchState({
                      planSelectedFrom: "partner",
                    }),
                  );
                  const isArray = JSON.parse(e.target.value).isArray;
                  if (!isArray) {
                    const newValue =
                      plansMode === "samePlan"
                        ? { comparedPlanYou: e.target.value }
                        : { comparedPlanSpouse: e.target.value };
                    dispatch(Actions.Creators.switchState(newValue));
                  }
                }}
                value={plansMode === "samePlan" ? comparedPlanYou : comparedPlanSpouse}
                style={{ display: "contents" }}
              >
                {renderRows(
                  partnerPlansNames,
                  plansMode,
                  deductibleType,
                  groupType,
                  broaderNetwork,
                  recommendedColumnPartner,
                  sortedPartnerPlans,
                  costPeriod,
                  partnerPlansStats,
                  allPlansStats,
                  comparisonMode,
                  isRecommendedRowPartner,
                  _i,
                  partnerCompany,
                  handleCompare,
                  size,
                  children,
                  true,
                  hasKid,
                  isInNetworkLimits,
                  dispatch,
                  reportVersion,
                  preference,
                  recommendedDisplayValue,
                  bestOptimization,
                  allPlanOptions,
                  tableType,
                )}
              </Radio.Group>
            </RowLineWrapper>
          );
        })}
        <AllPlansModal
          modal={modal}
          show={modalVisible}
          withoutDecimalCases={isInNetworkLimits}
          isInNetworkLimits={isInNetworkLimits}
          close={() => setModalVisible(false)}
        />
      </div>
    </TableBodyWrapper>
  );
};

export default TableBody;
