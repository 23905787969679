import React, { useState } from "react";
import styled from "styled-components";
import { CalculateHeader as Header, Text, Tooltip } from "components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import { Popconfirm } from "antd";
import { updateNetworksGrid } from "services/api/";
import { insuranceNetworkDisplayNameMap } from "enums/insuranceNetworkDisplayNameMap.enums";

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 82%;
  transition: left 0.4s ease-in-out;
  position: relative;
  left: 0;
  margin-left: auto;
  margin-right: auto;
`;

const TestDiv = styled.div`
  margin: 25px 0;
  border: none;
  border-spacing: 0;
  align-items: center;
  overflow-x: auto;
  max-width: 100%;

  @media (max-width: 620px) {
    margin: 20px 10px;
  }
`;

const InnerWrapper = styled.table`
  margin: auto;
`;

const Row = styled.tr`
  ${(props) =>
    props.sticky &&
    `
    position: sticky;
    ${props.stickyPosition}: ${props.stickyOffset}px;
    background: #fff;
    z-index: 1;
  `}
`;

const TableBody = styled.tbody`
  /* display: inline-block;
  height: 300px;
  overflow-y: scroll; */
`;

const CellDiv = styled.div`
  text-align: ${(props) => (props.align ? props.align : "center")};
  padding: 2px;
  width: 100px;
  ${(props) => props.border && `border-right: 1px solid white`};
  ${(props) => props.border && `border-bottom: 1px solid white`};
  min-height: 50px;
  height: ${(props) => (props.tall ? "80px" : props.short ? "35px" : props.medium ? "65px" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex !important")};
  align-items: center !important;
  ${(props) =>
    props.justifyContent
      ? `justify-content: ${props.justifyContent} !important`
      : `justify-content: center !important`};

  transition: all 0.3s ease;

  ${({ background, opacity }) =>
    background === "green"
      ? `background: rgba(147, 193, 159, ${opacity ? opacity : 1})`
      : background === "red"
      ? `background: rgba(246,180,183, ${opacity ? opacity : 1})`
      : background === "grey"
      ? "background: rgba(249, 249, 249)"
      : background === "catastrophic"
      ? "background: rgba(177, 30, 26)"
      : background === "bronze"
      ? "background: rgba(188, 106, 46)"
      : background === "silver"
      ? // ? "background: rgba(216, 215, 215)"
        "background: #F2F4F7"
      : background === "gold"
      ? "background: rgba(248, 191, 80)"
      : background === "platinum"
      ? "background: rgba(192,192,192)"
      : background === "recommended"
      ? "background: rgba(28,53,94)"
      : "background: rgba(255,255,255,0)"};

      :hover {
        ${(props) =>
          props.canEdit &&
          `
        cursor: pointer;
      `}
`;

const TableCell = styled.td`
  border: none;
  background-color: #fff;
  padding: 1px 1px;
  height: 0px;

  ${(props) =>
    props.sticky &&
    `
    position: sticky;
    left: 0;
  `}
`;

const StyledCell = (props) => (
  <TableCell sticky={props.sticky}>
    <CellDiv {...props}>{props.children}</CellDiv>
  </TableCell>
);

const InNetworkCare = ({ report, optimization, broaderNetworks, providersGrid, gridId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const canEdit = !report;
  const [showTooltip, setShowTooltip] = useState(true);
  if (!broaderNetworks && !providersGrid) {
    broaderNetworks = report.broaderNetworks;
    providersGrid = report.providersGrid;
  }

  const seenInsuranceCompanies = [];
  broaderNetworks.forEach((network) => {
    if (!seenInsuranceCompanies.includes(network.name)) {
      seenInsuranceCompanies.push(network.name);
    }
  });
  const insuranceCompanyCount = seenInsuranceCompanies.length;

  if (insuranceCompanyCount > 1) {
    broaderNetworks.forEach((network) => {
      const insuranceCompanyDisplayName = insuranceNetworkDisplayNameMap[network.name] || network.name;
      if (network.row2 && !network.row2.includes(insuranceCompanyDisplayName)) {
        network.row2 = `${insuranceCompanyDisplayName} ${network.row2}`;
      }
    });
  }

  const [networks] = useState(broaderNetworks);
  const [providers, setProviders] = useState(providersGrid);

  const lastRow = providers.reduce((acc, _provider) => {
    _provider.networks.forEach((_network, i) => {
      acc[i] += _network.accept ? 1 : 0;
    });
    return acc;
  }, new Array(networks.length).fill(0));

  // const recommendedIndex = useSelector((state) => state.report.recommendedIndex);
  // const recommendedFor = useSelector((state) => state.report.recommendedFor);
  const recommendedIndex = [];
  const recommendedFor = [];

  const handleFlipProvider = (i, j) => {
    if (providers?.[i]?.networks?.[j]) {
      providers[i].networks[j].accept = !providers[i].networks[j].accept;
      setProviders([...providers]);
      updateNetworksGrid({ id: gridId, form: { providersGrid: providers } });
    }
  };

  return (
    <Wrapper>
      <div style={{ width: "100%" }}>
        <Header title="What's in-network?" bigTitle />
      </div>
      <TestDiv>
        <InnerWrapper>
          <Row
            style={{ flexFlow: "row" }}
            align="middle"
            justify="center"
            wrap={false}
            sticky
            stickyPosition="top"
            stickyOffset="0"
          >
            <StyledCell medium={optimization?.optimizationDetails?.join === "separate"} sticky />
            {networks.map((_network, i) => (
              <StyledCell
                key={`recommendedRow${i}`}
                short={!optimization?.heading.includes("Separate")}
                border
                background={recommendedIndex.includes(i) ? "recommended" : ""}
                borderTopLeft
                borderTopRight
                borderBottomLeft
                borderBottomRight
              >
                {recommendedIndex.includes(i) && (
                  <Text white bold fontSize="12">
                    {"Recommended"}
                    <Text fontSize={12}>{recommendedFor[recommendedIndex.indexOf(i)]}</Text>
                  </Text>
                )}
              </StyledCell>
            ))}
          </Row>
          <Row
            style={{ flexFlow: "row" }}
            align="middle"
            justify="center"
            wrap={false}
            sticky
            stickyPosition="top"
            stickyOffset="50"
          >
            <StyledCell sticky />
            {networks.map((_network, i) => (
              <Tooltip title={showTooltip && _network.extraInstructions} key={`networks${_network.name}`}>
                <Popconfirm
                  title="Would you like to edit these networks and providers?"
                  disabled={!canEdit}
                  onConfirm={() => {
                    localStorage.removeItem("networksForm");
                    dispatch(Actions.Creators.changeCurrent(0));
                    history.push("/edit-networks", {
                      gridId,
                    });
                  }}
                  onCancel={() => {
                    setShowTooltip(true);
                  }}
                  okText="Yes"
                  cancelText="No"
                  onOpenChange={(open) => {
                    if (open) {
                      canEdit && setShowTooltip(false);
                    } else {
                      setShowTooltip(true);
                    }
                  }}
                >
                  <StyledCell
                    tall
                    border
                    background={recommendedIndex.includes(i) ? "recommended" : "silver"}
                    borderTopLeft={i === 0}
                    borderTopRight={i === networks.length - 1}
                    canEdit={canEdit}
                  >
                    <Text bold white={recommendedIndex.includes(i)} blue={!recommendedIndex.includes(i)} fontSize="16">
                      {_network.row2 ? _network.row2 : _network.name}
                    </Text>
                  </StyledCell>
                </Popconfirm>
              </Tooltip>
            ))}
          </Row>
          {networks.some((network) => network.row2 && network.row2 !== "") && (
            <Row
              style={{ flexFlow: "row" }}
              align="middle"
              justify="center"
              wrap={false}
              sticky
              stickyPosition="top"
              stickyOffset="130"
            >
              <StyledCell short sticky />
              {networks.map((_network) => (
                <Tooltip title={showTooltip && _network.extraInstructions} key={`networkDirectorys${_network.name}`}>
                  <Popconfirm
                    title="Would you like to edit these networks and providers?"
                    disabled={!canEdit}
                    onConfirm={() => {
                      localStorage.removeItem("networksForm");
                      dispatch(Actions.Creators.changeCurrent(0));
                      history.push("/edit-networks", {
                        gridId,
                      });
                    }}
                    onCancel={() => {
                      setShowTooltip(true);
                    }}
                    okText="Yes"
                    cancelText="No"
                    onOpenChange={(open) => {
                      if (open) {
                        canEdit && setShowTooltip(false);
                      } else {
                        setShowTooltip(true);
                      }
                    }}
                  >
                    <StyledCell short canEdit={canEdit}>
                      {_network.row2 ? (
                        <>
                          {_network.name.length <= 36 ? (
                            <Text fontSize={11}>{_network.name}</Text>
                          ) : (
                            <Tooltip title={_network.name}>
                              <Text fontSize={11}>{_network.name.slice(0, 36) + "..."}</Text>
                            </Tooltip>
                          )}
                        </>
                      ) : null}
                    </StyledCell>
                  </Popconfirm>
                </Tooltip>
              ))}
            </Row>
          )}
          <Row
            style={{ flexFlow: "row" }}
            align="middle"
            justify="center"
            wrap={false}
            sticky
            stickyPosition="top"
            stickyOffset="130"
          >
            <StyledCell short sticky />
            {networks.map((_network) => (
              <StyledCell key={`networkDirectorys${_network.name}`} short>
                {_network.url ? (
                  <a
                    target="_blank"
                    disabled={!_network.url}
                    href={_network.url.includes("http") ? _network.url : `http://${_network.url}`}
                    style={{
                      fontSize: 11,
                    }}
                    rel="noreferrer"
                  >
                    {"View directory"}{" "}
                    <svg
                      style={{ marginLeft: "3px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="10"
                      height="10"
                      viewBox="0,0,256,256"
                    >
                      <g transform="">
                        <g fill="#339af0">
                          <g transform="scale(5.12,5.12)">
                            <path d="M33.40625,0c-0.55078,0.05078 -0.95703,0.54297 -0.90625,1.09375c0.05078,0.55078 0.54297,0.95703 1.09375,0.90625h12.96875l-20.875,20.90625c-0.29687,0.24219 -0.43359,0.62891 -0.34766,1.00391c0.08594,0.37109 0.37891,0.66406 0.75,0.75c0.375,0.08594 0.76172,-0.05078 1.00391,-0.34766l20.90625,-20.875v12.96875c-0.00391,0.35938 0.18359,0.69531 0.49609,0.87891c0.3125,0.17969 0.69531,0.17969 1.00781,0c0.3125,-0.18359 0.5,-0.51953 0.49609,-0.87891v-16.40625h-16.40625c-0.03125,0 -0.0625,0 -0.09375,0c-0.03125,0 -0.0625,0 -0.09375,0zM2,10c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v36c0,0.52344 0.18359,1.05859 0.5625,1.4375c0.37891,0.37891 0.91406,0.5625 1.4375,0.5625h36c0.52344,0 1.05859,-0.18359 1.4375,-0.5625c0.37891,-0.37891 0.5625,-0.91406 0.5625,-1.4375v-30c0.00391,-0.35937 -0.18359,-0.69531 -0.49609,-0.87891c-0.3125,-0.17969 -0.69531,-0.17969 -1.00781,0c-0.3125,0.18359 -0.5,0.51953 -0.49609,0.87891v30h-36v-36h30c0.35938,0.00391 0.69531,-0.18359 0.87891,-0.49609c0.17969,-0.3125 0.17969,-0.69531 0,-1.00781c-0.18359,-0.3125 -0.51953,-0.5 -0.87891,-0.49609z"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                ) : (
                  <Text fontSize={11}>{"No directory"}</Text>
                )}
              </StyledCell>
            ))}
          </Row>
          <TableBody>
            {providers.map((_provider, i) => (
              <Row
                key={`providers${_provider.name}`}
                style={{ flexFlow: "row", height: "fit-content" }}
                align="middle"
                justify="center"
                wrap={false}
              >
                <Popconfirm
                  title="Would you like to edit these networks and providers?"
                  disabled={!canEdit}
                  onConfirm={() => {
                    localStorage.removeItem("networksForm");
                    dispatch(Actions.Creators.changeCurrent(0));
                    history.push("/edit-networks", {
                      gridId,
                    });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <StyledCell sticky justifyContent="start" align="left" canEdit={canEdit}>
                    <Text fontSize={14} align="left">
                      {_provider.name}
                    </Text>
                  </StyledCell>
                </Popconfirm>
                {_provider.networks.map((_network, j) => (
                  <Popconfirm
                    title={`Do you want to change this to ${!_network.accept ? "Yes" : "No"}?`}
                    disabled={!canEdit}
                    onConfirm={() => {
                      handleFlipProvider(i, j);
                    }}
                    okText="Yes"
                    cancelText="No"
                    key={`accept${_provider.name},${_network.name}`}
                  >
                    <StyledCell
                      border
                      background={_network.accept ? "green" : "red"}
                      opacity={0.5}
                      borderBottomLeft={j === 0 && i === providers.length - 1}
                      borderBottomRight={j === _provider.networks.length - 1 && i === providers.length - 1}
                      canEdit={canEdit}
                    >
                      <Text fontSize={16}>{_network.accept ? "Yes" : "No"}</Text>
                    </StyledCell>
                  </Popconfirm>
                ))}
              </Row>
            ))}
          </TableBody>
          <Row
            style={{ flexFlow: "row" }}
            align="middle"
            justify="center"
            wrap={false}
            sticky
            stickyPosition="bottom"
            stickyOffset="50"
          >
            <StyledCell sticky />
            {networks.map((_network, i) => (
              <StyledCell key={i} />
            ))}
          </Row>
          <Row
            style={{ flexFlow: "row" }}
            align="middle"
            justify="center"
            wrap={false}
            sticky
            stickyPosition="bottom"
            stickyOffset="0"
          >
            <StyledCell sticky justifyContent="start" align="left">
              <Text fontSize={14} bold align="left">
                {"Total"}
              </Text>
            </StyledCell>
            {lastRow.map((element, i) => (
              <StyledCell
                key={`totals[${i}]`}
                border
                background={
                  recommendedIndex.includes(i)
                    ? "recommended"
                    : element > providers.length / 2
                    ? "green"
                    : element < providers.length / 2
                    ? "red"
                    : "grey"
                }
                opacity={recommendedIndex.includes(i) ? 1 : 0.5}
                borderBottomLeft={i === 0}
                borderTopLeft={i === 0}
                borderTopRight={i === lastRow.length - 1}
                borderBottomRight={i === lastRow.length - 1}
              >
                <Text fontSize={14} white={recommendedIndex.includes(i)}>
                  {element}
                </Text>
              </StyledCell>
            ))}
          </Row>
        </InnerWrapper>
      </TestDiv>

      <Text fontSize="14" italic padding="2px">
        {"Please note: provider websites may not always be active or up-to-date."}
      </Text>
    </Wrapper>
  );
};

export default InNetworkCare;
